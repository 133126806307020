import { Typography } from "@mui/material";

type Props = {
  content: string;
};

const Content = ({ content }: Props) => {
  return (
    <Typography variant="body2" p={{ xs: 0, md: 1 }}>
      {content}
    </Typography>
  );
};

export default Content;
