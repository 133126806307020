import Title from "../components/Title";
import Content from "../components/Content";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import ImgGallery from "../components/ImgGallery";
import NewsEventsComponents from "../components/home/NewsEventsComponents";
import facilityImages from "../static_data/home/facilityGallery.json";

import PersonIcon from "@mui/icons-material/Person";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import SchoolIcon from "@mui/icons-material/School";

const Home = () => {
  return (
    <Box>
      {/* Welcome Text */}
      <Grid
        sx={{ backgroundColor: "secondary.main" }}
        container
        p={2}
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 1, md: 2 }}
        alignItems={"flex-start"}
        justifyContent={"space-around"}
      >
        <Grid item xs={1} md={1.2}>
          <Stack direction={"column"}>
            <Title title="Welcome To" highlighter="St. Xaviers" />
            <Content content="The Coat of Arms of our school. In it are enshrined the name, the motto and the ideals of our school. A word of explanation by way of introducing the main features of the Coat of Arms." />
            <Content content="The shield is divided into four fields, two in blue and two in gold. These being our school colours. On the top left field is the monogram of the society of Jesus which conducts the school. The letters, I.H.S. are an abbreviation of the Holy Name of Jesus and the cross surrounding it is the main symbol of the sufferings Jesus underwent in order to make this world a better place." />
            <Content content="To the top right is a portion of the coat of Arms of St. Francis Xavier after whom our school in named. St. Xavier, a heroic member of the Society of Jesus, spent his life in the service of mankind and more especially in the service of India. Below on the field to the left is a sugarcane bloom symbolizing Kolhapur, whose youth the school is privileged to educate. The lamp you see on the fourth field to the right is a fitting symbol of learning, the acquisition of which should be the main occupation of every Xavierite, The lamp also represents the school’s motto you see inscribed on the lower scroll: VIRTUE AND WORK. The work of study should lead to the acquisition of virtue." />
          </Stack>
        </Grid>

        {/* News And Events */}
        <Grid item xs={1} md={0.5}>
          <NewsEventsComponents />
        </Grid>
      </Grid>
      <ImgGallery
        title="Facilities"
        paddingLeft={5}
        imagesArray={facilityImages}
      />
      <Grid
        container
        sx={{ backgroundColor: "secondary.main" }}
        columns={{ xs: 1, md: 3 }}
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item xs={1} p={2}>
          <Stack
            sx={{ width: "100%" }}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton size="large" color="primary">
              <LocalLibraryIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h4">2500+</Typography>
            <Typography variant="body1">Students</Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} p={2}>
          <Stack
            sx={{ width: "100%" }}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton size="large" color="primary">
              <PersonIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h4">125+</Typography>
            <Typography variant="body1">Teachers</Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} p={2}>
          <Stack
            sx={{ width: "100%" }}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton size="large" color="primary">
              <SchoolIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h4">220+</Typography>
            <Typography variant="body1">Classrooms</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
