import { ComponentType, FC } from "react";

//Home Page
import Home from "../pages/Home";

//Xaviers SubMenu Pages
import About from "../pages/xaviers/About";
import Vision from "../pages/xaviers/Vision";
import Management from "../pages/xaviers/Management";
import History from "../pages/xaviers/History";

//Academics SubMenu Pages
import Admission from "../pages/academics/Admission";
import FeePolicy from "../pages/academics/FeePolicy";
import Sections from "../pages/academics/Sections";
import Departments from "../pages/academics/Departments";
import SchoolTimings from "../pages/academics/SchoolTimings";
import Houses from "../pages/academics/Houses";

//HR SubMenu Pages
import TeachingStaff from "../pages/hr/TeachingStaff";
import NonTeachingStaff from "../pages/hr/NonTeachingStaff";

//NewsEvent Pages
import NewsEvents from "../pages/NewsEvents";

//ContactUs Pages
import ContactUs from "../pages/ContactUs";
import PageNotFound from "../pages/error-page/PageNotFound";
import Facilities from "../pages/Facilities";
import PageUnderConstruction from "../pages/error-page/PageUnderConstruction";

//Policy Pages
import Cancellation from "../pages/policies/Cancellation";
import Conditions from "../pages/policies/Conditions";
import Privacy from "../pages/policies/Privacy";
import HyperLinking from "../pages/policies/HyperLinking";
import Copyright from "../pages/policies/Copyright";
import LegalDisclaimer from "../pages/policies/LegalDisclaimer";

// RouteItem is an interface for defining the application routes and navigation menu items
export interface RouteItem {
  key: string;
  title: string;
  tooltip?: string;
  path: string;
  component?: FC;
  enabled: boolean;
  icon?: ComponentType;
  subRoutes?: Array<RouteItem>;
  appendDivider?: boolean;
}

export const routes: Array<RouteItem> = [
  // {
  //   key: "router-file",
  //   title: "File Uploader",
  //   tooltip: "File Uploader",
  //   path: "*",
  //   enabled: true,
  //   component: DataStorageComponent,
  //   appendDivider: true,
  // },
  //error page route
  {
    key: "router-error",
    title: "Error",
    tooltip: "Page Not Found",
    path: "*",
    enabled: true,
    component: PageNotFound,
    appendDivider: true,
  },
  //home page route
  {
    key: "router-home",
    title: "Home",
    tooltip: "Home",
    path: "/",
    enabled: true,
    component: Home,
    appendDivider: true,
  },
  //xavier page route
  {
    key: "router-xavier",
    title: "About Us",
    tooltip: "About Us",
    path: "/xaviers",
    enabled: true,
    component: Home,
    appendDivider: true,
    subRoutes: [
      {
        key: "router-xavier-about",
        title: "About",
        tooltip: "About",
        path: "about",
        enabled: true,
        component: About,
        appendDivider: true,
      },
      {
        key: "router-xavier-vision",
        title: "Vision",
        tooltip: "Vision",
        path: "vision",
        enabled: true,
        component: Vision,
        appendDivider: true,
      },
      {
        key: "router-xavier-management",
        title: "Management",
        tooltip: "Management",
        path: "management",
        enabled: true,
        component: Management,
        appendDivider: true,
      },
      {
        key: "router-xavier-history",
        title: "History",
        tooltip: "History",
        path: "history",
        enabled: true,
        component: History,
        appendDivider: true,
      },
      {
        key: "router-xavier-principal-speech",
        title: "Principal's Speech",
        tooltip: "Principal's Speech",
        path: "principal_speech",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-xavier-school-anthem",
        title: "School Anthem",
        tooltip: "School Anthem",
        path: "school_anthem",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-xavier-past-principals",
        title: "Past Principals",
        tooltip: "PAst Principals",
        path: "past_principals",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-xavier-society-of-jesuits",
        title: "Society Of Jesuits",
        tooltip: "Society Of Jesuits",
        path: "society_of_jesuits",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-xavier-patrons_xavier",
        title: "Patrons Xavier",
        tooltip: "Patrons Xavier",
        path: "patrons_xavier",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
    ],
  },
  {
    key: "router-school-history",
    title: "School History",
    tooltip: "School History",
    path: "/school-history",
    enabled: true,
    appendDivider: true,
    subRoutes: [
      {
        key: "router-school-history-ded",
        title: "D.ed College History",
        tooltip: "D.ed College History",
        path: "school-history-ded",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-aims-objectives",
        title: "Aims and Objectives",
        path: "aims-objectives",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-discipline",
        title: "School discipline",
        path: "discipline",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-rules",
        title: "Rules",
        path: "rules",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-house-systems",
        title: "House Systems",
        path: "house-systems",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-curriculum",
        title: "Curriculum",
        path: "curriculum",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-school-activities",
        title: "School Activities",
        path: "school-activities",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-achievements",
        title: "Achievements",
        path: "achievements",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-school-calendar",
        title: "School Calendar",
        path: "school-calendar",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-why-xavier",
        title: "Why Xavier",
        path: "why-xavier",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-achievers",
        title: "Achievers / Toppers",
        path: "achievers-toppers",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
    ],
  },
  //academics page route
  {
    key: "router-academics",
    title: "Academics",
    tooltip: "Academics",
    path: "/academics",
    enabled: true,
    appendDivider: true,
    subRoutes: [
      {
        key: "router-academics-admission",
        title: "Admission Process",
        tooltip: "Admission Process",
        path: "admission-process",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-academics-feepolicy",
        title: "Fee Policy",
        tooltip: "Fee Policy",
        path: "fee-policy",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-academics-sections",
        title: "Sections",
        tooltip: "Sections",
        path: "sections",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-academics-departments",
        title: "Departments",
        tooltip: "Departments",
        path: "departments",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-academics-school-timings",
        title: "School Timings",
        tooltip: "School Timings",
        path: "school-timings",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
      {
        key: "router-academics-houses",
        title: "Houses",
        tooltip: "Houses",
        path: "houses",
        enabled: true,
        component: PageUnderConstruction,
        appendDivider: true,
      },
    ],
  },
  //hr page route
  {
    key: "router-hr",
    title: "Our Team",
    tooltip: "Our Team",
    path: "/human-resources",
    enabled: true,
    appendDivider: true,
    subRoutes: [
      {
        key: "router-hr-teaching-staff",
        title: "Teaching Staff",
        tooltip: "Teaching Staff",
        path: "teaching-staff",
        enabled: true,
        component: TeachingStaff,
        appendDivider: true,
      },
      {
        key: "router-hr-non-teaching-staff",
        title: "Non-Teaching Staff",
        tooltip: "Non-Teaching Staff",
        path: "non-teaching-staff",
        enabled: true,
        component: NonTeachingStaff,
        appendDivider: true,
      },
    ],
  },
  //Facilities route
  {
    key: "router-facility",
    title: "Facilities",
    tooltip: "Facilities",
    path: "/facility",
    enabled: true,
    component: Facilities,
    appendDivider: true,
  },
  //news-events route
  {
    key: "router-news-events",
    title: "News & Events",
    tooltip: "News & Events",
    path: "/news-events",
    enabled: true,
    component: NewsEvents,
    appendDivider: true,
  },
  //contact us route
  {
    key: "router-contact-us",
    title: "Contact",
    tooltip: "Contact",
    path: "/contact",
    enabled: true,
    component: ContactUs,
    appendDivider: true,
  },
  //policy routes
  {
    key: "router-policies",
    title: "Policies",
    tooltip: "Policies",
    path: "/policies",
    enabled: true,
    appendDivider: true,
    subRoutes: [
      {
        key: "router-legal-disclaimer",
        title: "Legal Disclaimer",
        tooltip: "Legal Disclaimer",
        path: "legalDisclaimer",
        component: LegalDisclaimer,
        enabled: true,
        appendDivider: true,
      },
      {
        key: "router-copyright-policy",
        title: "CopyRight Policy",
        tooltip: "CopyRight Policy",
        path: "CopyRightPolicy",
        component: Copyright,
        enabled: true,
        appendDivider: true,
      },
      {
        key: "router-hyperlinking-policy",
        title: "HyperLinking Policy",
        tooltip: "HyperLinking Policy",
        path: "hyperLinkingPolicy",
        component: HyperLinking,
        enabled: true,
        appendDivider: true,
      },
      {
        key: "router-privacy-policy",
        title: "Privacy Policy",
        tooltip: "Privacy Policy",
        path: "privacyPolicy",
        component: Privacy,
        enabled: true,
        appendDivider: true,
      },
      {
        key: "router-terms-conditions",
        title: "Terms & Conditions",
        tooltip: "Terms & Conditions",
        path: "Conditions",
        component: Conditions,
        enabled: true,
        appendDivider: true,
      },
      {
        key: "router-cancellation-refund-policy",
        title: "Cancellation & ReFund Policy",
        tooltip: "Cancellation & ReFund Policy",
        path: "Cancellations",
        component: Cancellation,
        enabled: true,
        appendDivider: false, // Last sub-route should not have an appendix divider
      },
    ],
  },
];
export default RouteItem;
