import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { IconButton, Link, Paper, Stack, Tooltip } from "@mui/material";
import headerConstants from "../../utils/headerConstant.json";
export default function QuickLinks() {
  const headers = headerConstants.headers;
  return (
    <Fade in={true}>
      <Stack
        direction={"column"}
        spacing={1}
        role="presentation"
        component={Paper}
        elevation={4}
        sx={{
          position: "fixed",
          bgcolor: "primary.main", // theme.palette.primary.main
          color: "secondary.light",
          p: 1,
          Radius: 10,
          bottom: "35%",
          zIndex: 2,
          right: 0,
        }}
      >
        <Tooltip title={`${headers?.phone}`}>
          <Link href={`tel:+91${headers?.phone}`} underline="hover">
            <IconButton
              sx={{
                color: "white",
                width: "fit-content",
                ":hover": {
                  color: "text.secondary",
                  bgcolor: "white",
                },
              }}
            >
              <PhoneIcon fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title={`${headers.email}`}>
          <Link href={`mailto:${headers.email}`} underline="hover">
            <IconButton
              sx={{
                color: "white",
                width: "fit-content",
                ":hover": {
                  color: "text.secondary",
                  bgcolor: "white",
                },
              }}
            >
              <EmailIcon fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>

        <IconButton
          sx={{
            color: "white",
            width: "fit-content",
            ":hover": {
              color: "text.secondary",
              bgcolor: "white",
            },
          }}
        >
          <YouTubeIcon fontSize="small" />
        </IconButton>
        <IconButton
          sx={{
            color: "white",
            width: "fit-content",
            ":hover": {
              color: "text.secondary",
              bgcolor: "white",
            },
          }}
        >
          <InstagramIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Fade>
  );
}
