import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button, Link, Stack, useMediaQuery, useTheme } from "@mui/material";
import constant from "../config/constants";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
type Props = {};

const navbars = [
  {
    name: "About",
    path: "/xaviers/about",
  },
  { name: "Facilities", path: "/facility" },
  { name: "News & Events", path: "/news-events" },
  { name: "Contact Us", path: "/contact" },
];

const ErpLinks = (props: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const tab = useMediaQuery("(max-width:1100px)");
  const matches = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();
  return (
    <Stack
      direction={"row"}
      flexWrap={"wrap-reverse"}
      spacing={2}
      width={"fit-content"}
      sx={{
        width: "fit-content",
        height: "100%",
        mr: 2,
      }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {!matches &&
        navbars?.map((menu: { name: string; path: string }, index: number) => (
          <Button
            component={motion.div}
            initial={{ scale: 0 }}
            whileInView={{
              scale: 1,
              transition: { duration: 0.5, delay: index * 0.1 },
            }}
            sx={{
              textTransform: "none",
              textWrap: "nowrap",
              width: { xs: "100%", md: "fit-content" },
              color: "white",
              bgcolor: "primary.main",
              // color: "customColors.clr1",
              ":hover": {
                bgcolor: "primary.main", // theme.palette.primary.main
                color: "#eed261",
              },
              padding: 0,
              fontSize: "1rem",
              py: 0.5,
              px: 0.5,
            }}
            onClick={() => {
              navigate(menu?.path);
            }}
          >
            {menu?.name}
          </Button>
        ))}
      {!isSmallScreen && (
        <>
          <Link
            href="https://sxs.akronsystems.com/admission/pONLINEFEEPAYMENT.aspx"
            target="_blank"
            sx={{
              textWrap: "nowrap",
              bgcolor: "#eed261",
              padding: 0,
              px: 0.5,
              fontWeight: "bold",
              color: "primary.main",
              py: 0.5,
              mt: 0.2,
              ":hover": {
                bgcolor: "secondary.light", // theme.palette.primary.main
                color: "primary.main",
              },
              minWidth: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "2px",
            }}
            underline="hover"
          >
            Pay Fees
            <ArrowRightAltIcon />
          </Link>
          <Link
            href="https://sxs.akronsystems.com/pLogin.aspx"
            target="_blank"
            underline="hover"
            sx={{
              minWidth: "150px",
              textWrap: "nowrap",
              bgcolor: "secondary.light",
              padding: 0,
              px: 0.5,
              py: 0.5,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ":hover": {
                bgcolor: "#eed261",
              },
            }}
          >
            {/* public\assets\images\webdesk_logo.png */}
            <img
              src={`${constant?.baseurl}assets/images/webdesk_logo.png`}
              alt="Webdesk Logo"
              height="15px"
            />
            Login
          </Link>
        </>
      )}
    </Stack>
  );
};

export default ErpLinks;
