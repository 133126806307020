import newsImages from "../../static_data/newsImages.json";
import ImgGallery from "../ImgGallery";
type Props = {};

const News = (props: Props) => {
  return (
    <ImgGallery
      title=""
      paddingLeft={0}
      imagesArray={newsImages}
      color="secondary.main"
    />
  );
};

export default News;
