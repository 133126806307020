import { Box, Paper, Stack, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import constant from "../config/constants";
import Carousel from "react-material-ui-carousel";
import { SportsRugbySharp } from "@mui/icons-material";
interface IMainProps {
  key: number;
  src: string;
  description: string;
}
interface ICIProps {
  main: IMainProps[];
  currentPath: string;
  parentPath: string;
}

const CarouselComponent = ({ main, currentPath, parentPath }: ICIProps) => {
  return (
    <Carousel sx={{ width: "100vw" }} indicators={false}>
      {main.map((item, i) => (
        <Item
          key={i}
          item={item}
          currentPath={currentPath}
          parentPath={parentPath}
        />
      ))}
    </Carousel>
  );
};

export default CarouselComponent;

interface ItemProps {
  key: number;
  item: {
    key: number;
    src: string;
    description?: string;
  };
  currentPath: string;
  parentPath: string;
}
function Item({ key, item, currentPath, parentPath }: ItemProps) {
  return (
    <Box
      sx={{
        // backgroundImage: `url(${constant.baseurl}/${item.src})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "100% 100%",
        // objectFit: "cover",
        height: "400px",
        minWidth: "100vw",
        position: "relative",
        background: "rgba(0,0,0,0.8)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          background: "rgba(0,0,0,0.4)",
        }}
      ></Box>
      <img src={`${constant.baseurl}/${item.src}`} width="100%" height="100%" />
      <Typography
        variant="h5"
        sx={{
          backgroundColor: "#eed261",
          p: item?.description ? 1 : 0,
          position: "absolute",
          width: "fit-content",
          bottom: "40%",
        }}
      >
        {item.description}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        px={1}
        sx={{
          backgroundColor: "yellow",
          width: "fit-content",
          position: "absolute",
          bottom: 1,
          opacity: 0.5,
        }}
      >
        <HomeIcon />
        {parentPath !== "home" && (
          <>
            <ArrowRightIcon />
            {parentPath[0].toUpperCase() + parentPath.slice(1)}
          </>
        )}
        {currentPath.length > 0 && (
          <>
            <ArrowRightIcon />
            {currentPath[0].toUpperCase() + currentPath.slice(1)}
          </>
        )}
      </Stack>
    </Box>
  );
}
