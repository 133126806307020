import {
  Stack,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  ListItemIcon,
  Link,
  Divider,
  IconButton,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import SendIcon from "@mui/icons-material/Send";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
interface footerLink {
  link: string;
  path: string;
}
const TrialFooter = () => {
  const whyChoose: Array<string> = [
    "Student Support Services",
    "Auditorium With Stage",
    "Personal Teaching",
    "Human Resources",
    "Well Experienced Teachers",
  ];
  const footerLinks: Array<footerLink> = [
    {
      link: "About Us",
      path: "/xaviers/about",
    },
    {
      link: "News & Events",
      path: "/news-events",
    },
    {
      link: "Download Time Table",
      path: "*",
    },
    {
      link: "Contact Us",
      path: "/contact",
    },
    {
      link: "Terms and Conditions For Online Payment",
      path: "/terms-conditions",
    },
  ];
  return (
    <Stack
      direction={"row"}
      sx={{
        backgroundColor: "#9f9f9f",
        width: "100vw",
        position: "relative",
        p: { xs: 1, md: 5 },
        clear: "both",
        bottom: 0,
        color: "white",
      }}
    >
      <Grid container columns={{ xs: 12, sm: 18 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" component="div">
            Why Choose Us
          </Typography>
          <List sx={{ width: "100%", maxWidth: 360 }}>
            {whyChoose.map((value) => (
              <ListItem key={value} disablePadding>
                <ListItemIcon>
                  <StarIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={`${value}`} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" component="div">
            Useful Links
          </Typography>
          <List sx={{ width: "100%", maxWidth: 360 }}>
            {footerLinks.map((value) => (
              <ListItem key={value.link} sx={{ my: 1 }} disablePadding>
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
                <Link
                  sx={{
                    color: "secondary.main",
                    ":hover": {
                      color: "black",
                    },
                  }}
                  href={`${value.path}`}
                  underline="hover"
                >
                  {value.link}
                </Link>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" component="div">
            Get In Touch
          </Typography>
          <List sx={{ width: "100%", maxWidth: 360 }}>
            <ListItem disablePadding>
              <ListItemIcon>
                <LocationOnIcon />
              </ListItemIcon>
              <ListItemText primary="15 E, Nagala Park, Kolhapur, Maharashtra 416003" />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CallIcon />
              </ListItemIcon>
              <ListItemText primary="+91 9021703219" />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary="xaviers.kolhapur@gmail.com" />
            </ListItem>
            <Divider />
            <Stack
              direction="row"
              spacing={1}
              mt={2}
              alignItems="center"
              justifyContent="flex-start"
            >
              Follow Us On&nbsp;
              <IconButton
                aria-label="FACEBOOK"
                size="medium"
                sx={{
                  color: "white",
                  backgroundColor: "#484746",
                  ":hover": { color: "primary.main", backgroundColor: "white" },
                }}
              >
                <FacebookOutlinedIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="TWITTEr"
                size="medium"
                sx={{
                  color: "white",
                  backgroundColor: "#484746",
                  ":hover": { color: "primary.main", backgroundColor: "white" },
                }}
              >
                <TwitterIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </List>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TrialFooter;
