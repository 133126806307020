import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { routes } from "./config";

import RouteItem from "./config";
import Layout2 from "./layouts/Drawer";
function App() {
  return (
    <Router>
      <Layout2>
        <Routes>
          {routes.map((route: RouteItem) =>
            route.subRoutes ? (
              route.subRoutes.map((item: RouteItem) => (
                <Route key={`${route.key}`} path={`${route.path}`}>
                  <Route
                    key={`${item.key}`}
                    path={`${item.path}`}
                    Component={item.component}
                  />
                </Route>
              ))
            ) : (
              <Route
                key={`${route.key}`}
                path={`${route.path}`}
                Component={route.component}
              />
            )
          )}
        </Routes>
      </Layout2>
    </Router>
  );
}

export default App;
