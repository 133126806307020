import { Stack, Typography, useMediaQuery } from "@mui/material";
import constant from "../config/constants";

type Props = {
  title: string | undefined;
  highlighter?: string;
};

const Title = ({ title, highlighter }: Props) => {
  const matches = useMediaQuery("(max-width:900px)");

  return (
    <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
      {matches ? (
        <Typography variant="h6">
          {title}&nbsp;
          <span style={{ color: "#158cde" }}>{highlighter}</span>
        </Typography>
      ) : (
        <Typography variant="h4">
          {title}&nbsp;
          <span style={{ color: "#158cde" }}>{highlighter}</span>
        </Typography>
      )}
      <img
        src={`${constant.baseurl}/assets/images/titleRightD.png`}
        alt="title-right"
      />
    </Stack>
  );
};

export default Title;
