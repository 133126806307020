import BodyLayout from "../../layouts/BodyLayout";
import Title from "../../components/Title";
import { Stack } from "@mui/material";
import Content from "../../components/Content";

type Props = {};

const About = (props: Props) => {
  return (
    <BodyLayout>
      <Stack direction={"column"} width="95vw">
        <Title title="About" highlighter="St. Xaviers" />
        <Content content="The Coat of Arms of our school. In it are enshrined the name, the motto and the ideals of our school. A word of explanation by way of introducing the main features of the Coat of Arms." />
        <Content content="The shield is divided into four fields, two in blue and two in gold. These being our school colours. On the top left field is the monogram of the society of Jesus which conducts the school. The letters, I.H.S. are an abbreviation of the Holy Name of Jesus and the cross surrounding it is the main symbol of the sufferings Jesus underwent in order to make this world a better place." />
        <Content content="To the top right is a portion of the coat of Arms of St. Francis Xavier after whom our school in named. St. Xavier, a heroic member of the Society of Jesus, spent his life in the service of mankind and more especially in the service of India. Below on the field to the left is a sugarcane bloom symbolizing Kolhapur, whose youth the school is privileged to educate." />
        <Content content="The lamp you see on the fourth field to the right is a fitting symbol of learning, the acquisition of which should be the main occupation of every Xavierite, The lamp also represents the school’s motto you see inscribed on the lower scroll: VIRTUE AND WORK. The work of study should lead to the acquisition of virtue." />
        <Content content="Let this simple Coat of Arms be an inspiration to our students to fulfill in them the grand ideals of their Alma Mater." />
      </Stack>
    </BodyLayout>
  );
};

export default About;
