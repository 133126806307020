import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import NewsEventsSlider from "../../components/home/NewsEventsSlider";
import { motion, useMotionValue, animate } from "framer-motion";
import Title from "../../components/Title";
import useMeasure from "react-use-measure";
import { useEffect, useState } from "react";
import news from "../../static_data/home/news.json";
import events from "../../static_data/home/events.json";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Props = {};
interface INewsEventsProps {
  title: string;
  description?: string;
  date: string;
  type?: string;
}

const parseDate = (dateStr: string): Date => {
  const [day, month, year] = dateStr.split("-").map(Number);
  return new Date(year, month, day);
};
const sortEventByDate = (
  events: Array<{ imgArray?: any; title: string; date: string }>
) => {
  return events.sort(
    (a, b) => parseDate(a.date).getTime() - parseDate(b.date).getTime()
  );
};
const NewsEventsComponents = (props: Props) => {
  const latestNews: INewsEventsProps[] = news;
  const latestEvents: INewsEventsProps[] = sortEventByDate(events).reverse();

  let [ref, { height }] = useMeasure();
  const yTranslation = useMotionValue(0);

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    let controls;
    let finalPosition = -height;
    controls = animate(yTranslation, [0, finalPosition], {
      ease: "linear",
      duration: 30,
      repeat: Infinity,
      repeatType: "loop",
      repeatDelay: 0,
    });
    return controls.stop;
  });
  return (
    <Stack direction="column">
      <Title title="News & Events" />
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="News" />
        <Tab label="Events" />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <Stack
          direction="column"
          sx={{
            height: "300px",
            backgroundColor: "whitesmoke",
            overflow: "auto",
          }}
          ref={ref}
        >
          {latestNews?.length > 0 ? (
            <motion.div style={{ y: yTranslation, height: "100%" }}>
              {[...latestNews, ...latestNews].map((nw, i) => (
                <NewsEventsSlider {...nw} />
              ))}
            </motion.div>
          ) : (
            "Currently, there are no new updates to report. Please check back later for the latest news and updates."
          )}
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Stack
          component={motion.div}
          direction="column"
          sx={{
            height: "300px",
            backgroundColor: "whitesmoke",
            overflow: "auto",
          }}
        >
          <motion.div style={{ y: yTranslation, height: "100%" }} ref={ref}>
            {[...latestEvents, ...latestEvents].map((nw, i) => (
              <NewsEventsSlider {...nw} />
            ))}
          </motion.div>
        </Stack>
      </CustomTabPanel>
    </Stack>
  );
};

export default NewsEventsComponents;
