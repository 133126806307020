import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

export interface LayoutProps {
  children: ReactNode;
}

const BodyLayout: FC<LayoutProps> = ({ children }) => {
  return (
    <Box p={{ xs: 1, md: 2 }} sx={{ width: "100vw" }}>
      {children}
    </Box>
  );
};

export default BodyLayout;
