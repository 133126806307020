import {
  Stack,
  Grid,
  Typography,
  Box,
  Paper,
  ThemeProvider,
} from "@mui/material";
import Title from "./Title";
import { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

interface IimageI {
  src: string;
  title: string;
}
type Props = {
  title?: string;
  paddingLeft: number;
  imagesArray: IimageI[];
  color?: string;
};

const ImgGallery = ({ title, paddingLeft, imagesArray, color }: Props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const imgSrcs: Array<string> = [];
  for (let i in imagesArray) {
    imgSrcs.push(imagesArray[i]["src"]);
  }
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <Stack
      sx={{
        width: "90vw",
        pl: { xs: 0, md: paddingLeft },
        backgroundColor: color,
      }}
      alignItems={"flex-start"}
      justifyContent={"center"}
    >
      {title && title?.length > 0 ? (
        <Title title={title === "undefined" ? "" : title} />
      ) : (
        ""
      )}

      <Grid
        container
        sx={{
          width: { xs: "90vw", md: "90vw" },
          margin: { xs: "auto", md: 0 },
        }}
        columns={{ xs: 1, sm: 3, md: 4 }}
        spacing={4}
      >
        {imagesArray.map((item, i) => (
          <Grid
            item
            xs={1}
            key={i}
            sx={{ cursor: "pointer", position: "relative" }}
            onClick={() => {
              openImageViewer(i);
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "80%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(0,0,0,0.2)",
                  backdropFilter: "saturate(180%) blur(5px)",
                  borderRadius: "10px",
                  ":hover": {
                    background: "transparent",
                    backdropFilter: "none",
                  },
                  transition: "background 10s, backdropFilter 10s",
                }}
              ></Box>
              <img
                width="100%"
                height="100%"
                style={{ borderRadius: "10px" }}
                src={`${item.src}`}
                alt={item.title}
                loading="lazy"
              />
            </Box>
            <Typography>{item.title}</Typography>
          </Grid>
        ))}
        <>
          {isViewerOpen && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100vh",
                width: "100vw",
                zIndex: 1000000,
                p: 5,
              }}
            >
              <ImageViewer
                src={imgSrcs}
                backgroundStyle={{
                  background: "#158cde",
                }}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            </Box>
          )}
        </>
      </Grid>
    </Stack>
  );
};

export default ImgGallery;
