import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  title: string | undefined;
  description?: string;
  date: string;
  type?: string;
  key?: number;
};

const NewsEventsSlider = ({ title, description, date, type, key }: Props) => {
  const navigate = useNavigate();
  return (
    <Stack direction={"column"} mb={2}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">{description}</Typography>
      <Stack>
        <Typography variant="body2">{date}</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={1}
        >
          <Typography variant="body2" color="primary.main">
            {type}
          </Typography>
          <Typography
            variant="body2"
            color="primary.main"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/news-events");
            }}
          >
            Read More
          </Typography>
        </Stack>
        <hr />
      </Stack>
    </Stack>
  );
};

export default NewsEventsSlider;
