import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import News from "../components/news_events/News";
import Events from "../components/news_events/Events";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ backgroundColor: "secondary.main", p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Props = {};

const NewsEvents = (props: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack direction="column">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="News" />
        <Tab label="Events" />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <News />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Events />
      </CustomTabPanel>
    </Stack>
  );
};

export default NewsEvents;
