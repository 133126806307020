import { Stack, Typography } from "@mui/material";
import React from "react";

type Props = {};

const LegalDisclaimer = (props: Props) => {
  return (
    <Stack direction="column" alignItems={"center"} p={2} spacing={2}>
      <Typography variant="h6" textAlign={"center"}>
        The official website of ST.XAVIER'S SCHOOL KOLHAPUR is{" "}
        <a href="http://stxavierskop.edu.in/" target="_blank">
          http://stxavierskop.edu.in/
        </a>
        .
      </Typography>

      <Typography variant="body2" textAlign="justify">
        All the contents of this website are only for general information or
        use. ST.XAVIER'S SCHOOL KOLHAPUR excludes any warranty, express or
        implied, as to the quality, accuracy, and completeness of the website.
      </Typography>

      <Typography variant="body2" textAlign="justify">
        ST.XAVIER'S SCHOOL KOLHAPUR will not be liable for any damages arising
        from the use of this website. The copyright of the proprietary material
        contained in this website remains solely with ST.XAVIER'S SCHOOL
        KOLHAPUR.
      </Typography>

      <Typography variant="body2" textAlign="justify">
        ST.XAVIER'S SCHOOL KOLHAPUR may provide, as a convenience, links to
        websites operated by other entities and persons, but does not endorse or
        accept any responsibility for the content or the use of such websites.
      </Typography>
    </Stack>
  );
};

export default LegalDisclaimer;
