import Title from "../components/Title";
import BodyLayout from "../layouts/BodyLayout";
import { Box, Button, Stack, Typography } from "@mui/material";

import ScheduleIcon from "@mui/icons-material/Schedule";

import headerConstants from "../utils/headerConstant.json";
type Props = {};

const ContactUs = (props: Props) => {
  return (
    <BodyLayout>
      <Title title="Contact Us" />
      <Stack
        direction="column"
        my={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          sx={{ backgroundColor: "#eed261", width: "80%" }}
          p={{ xs: 2, sm: 4 }}
        >
          <Typography variant="h5">
            15 E, Nagala Park, Kolhapur, Maharashtra 416003
          </Typography>
        </Box>
        <Button
          sx={{
            backgroundColor: "black",
            color: "white",
            ":hover": {
              color: "black",
              backgroundColor: "white",
            },
            width: "90%",
            mb: 2,
          }}
        >
          Office Time - <ScheduleIcon />
          {headerConstants.headers.officeTime}
        </Button>
        <iframe
          title="Maps"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7642.4771818925265!2d74.235125!3d16.714943!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc1006422211e9f%3A0x6c0fb376309d9bdd!2sSt%20Xavier&#39;s%20High%20School!5e0!3m2!1sen!2sus!4v1716894106050!5m2!1sen!2sus"
          width="80%"
          height="600"
          loading="lazy"
        ></iframe>
      </Stack>
    </BodyLayout>
  );
};

export default ContactUs;
