import { Stack, Typography, useScrollTrigger } from "@mui/material";
import { useState } from "react";
import constant from "../../config/constants";
import { useNavigate } from "react-router-dom";
import ErpLinks from "../../components/ErpLinks";

type Props = {};

const TrialHeader = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Stack
      flexDirection={"row"}
      sx={{ width: "100%", height: "100%" }}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        sx={{ height: "8vh", cursor: "pointer", py: { xs: 1, sm: 0 } }}
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          height={"100%"}
          src={`${constant?.baseurl}/assets/images/logo.png`}
          alt="logo"
        />
        <Typography variant="h6" noWrap color="secondary">
          St. Xavier's School, Kolhapur
        </Typography>
      </Stack>
      <ErpLinks />
    </Stack>
  );
};

export default TrialHeader;
