import { Stack, Typography } from "@mui/material";
import React from "react";

type Props = {};

const Cancellation = (props: Props) => {
  return (
    <Stack direction="column" alignItems={"center"} p={2} spacing={2}>
      <Typography variant="h5" textAlign={"justify"}>
        Cancellation & Refund Policy
      </Typography>
      <Typography variant="body2" textAlign={"justify"}>
        No cancellations and refunds are entertained for the payments made to
        ST.XAVIER'S SCHOOL KOLHAPUR.
      </Typography>
    </Stack>
  );
};

export default Cancellation;
