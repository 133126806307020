import BodyLayout from "../../layouts/BodyLayout";
import Title from "../../components/Title";
import constant from "../../config/constants";
import { Box } from "@mui/material";

type Props = {};

const TeachingStaff = (props: Props) => {
  return (
    <BodyLayout>
      <Title title="Teaching Staff" />
      <Box my={2} width="80vw" mx="auto">
        <img
          src={`${constant.baseurl}/assets/images/hr/TeachingStaff.jpg`}
          alt="Non-Teaching Staff"
          width="100%"
        />
      </Box>
    </BodyLayout>
  );
};

export default TeachingStaff;
