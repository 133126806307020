import BodyLayout from "../../layouts/BodyLayout";
import Title from "../../components/Title";
import { Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Content from "../../components/Content";
type Props = {};

const Vision = (props: Props) => {
  const contents = [
    "Help the students to become mature, spiritually oriented persons of character free from egoism and self-seeking and from all attempts to exploit one’s fellowmen and women.",
    "To encourage them continually to strive for excellence in every field, without however neglecting the welfare and the needs of their fellowmen and women.",
    "To value and judiciously use their freedom.",
    "To be clear and firm on principles and courageous in action.",
    "To be clear and firm on principles and courageous in action.",
  ];
  return (
    <BodyLayout>
      <Title title="Vision" />
      {contents.map((content, i) => (
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"flex-start"}
          mb={1}
        >
          <ArrowForwardIosIcon fontSize="small" />
          <Content content={content} />
        </Stack>
      ))}
    </BodyLayout>
  );
};

export default Vision;
