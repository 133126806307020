import BodyLayout from "../../layouts/BodyLayout";
import Title from "../../components/Title";
import { Box } from "@mui/material";
import constant from "../../config/constants";

type Props = {};

const NonTeachingStaff = (props: Props) => {
  return (
    <BodyLayout>
      <Title title="Non-Teaching Staff" />
      <Box my={2} width="80vw" mx="auto">
        <img
          src={`${constant.baseurl}/assets/images/hr/NonTeachingStaff.jpg`}
          alt="Non-Teaching Staff"
          width="100%"
        />
      </Box>
    </BodyLayout>
  );
};

export default NonTeachingStaff;
