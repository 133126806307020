import { Stack, Typography } from "@mui/material";
import React from "react";

type Props = {};

const Privacy = (props: Props) => {
  return (
    <Stack direction="column" alignItems={"center"} p={2} spacing={2}>
      <Typography variant="h5" textAlign={"justify"}>
        Privacy Policy
      </Typography>
      <Typography variant="body2" textAlign="justify">
        ST.XAVIER'S SCHOOL KOLHAPUR respects your right to privacy. When you
        visit the official website of ST.XAVIER'S SCHOOL KOLHAPUR (
        <a href="http://stxavierskop.edu.in/" target="_blank">
          http://stxavierskop.edu.in/
        </a>
        ), the following information may be collected from you, either
        voluntarily or involuntarily: your computer or network’s IP address,
        your email address and message when you communicate electronically with
        us, and information about your visit gathered in an aggregate manner for
        quality control, security, and improvement of our website.
      </Typography>

      <Typography variant="body2" textAlign="justify">
        Your information is kept confidential, unaltered, and used only by
        ST.XAVIER'S SCHOOL KOLHAPUR to administer your request. ST.XAVIER'S
        SCHOOL KOLHAPUR does not disclose or sell any personally identifiable
        information collected on this website to other companies or
        organizations.
      </Typography>
    </Stack>
  );
};

export default Privacy;
