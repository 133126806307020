import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RouteItem, { routes } from "../../config";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from "@mui/material";
import constant from "../../config/constants";

type Props = {
  mobileOpen: boolean;
  setMobileOpen: Function;
  isClosing: boolean;
  setIsClosing: Function;
};
type MenuProps = {
  currentPath: string | null;
  parentPath: string;
  setCurrentPath: Function;
  setParentPath: Function;
  handleDrawerClose: Function;
};
const Menus = ({
  currentPath,
  setCurrentPath,
  parentPath,
  setParentPath,
  handleDrawerClose,
}: MenuProps) => {
  const navigate = useNavigate();
  const otherMenus = [
    {
      name: "Pay Fees",
      path: "https://sxs.akronsystems.com/admission/pONLINEFEEPAYMENT.aspx",
    },
    { name: "Login", path: "https://sxs.akronsystems.com/pLogin.aspx" },
  ];
  return (
    <div>
      <Toolbar
        sx={{
          height: "8vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          height={"60%"}
          src={`${constant?.baseurl}/assets/images/logo.png`}
          alt="logo"
        />
        <CloseIcon
          onClick={() => {
            handleDrawerClose();
          }}
          sx={{ ":hover": { color: "primary.main" } }}
        />
      </Toolbar>
      <Divider />
      <List>
        {routes?.map((menu: RouteItem, index: number) => (
          <>
            {menu.subRoutes &&
            menu.key !== "router-policies" &&
            menu.key !== "router-home" ? (
              <Accordion elevation={0} sx={{ width: "100% !important" }}>
                <AccordionSummary
                  sx={{
                    ":hover": {
                      color: "customColors.clr2",
                    },
                    padding: 0,
                    pl: 1,
                    width: "100vw",
                    justifyContent: "flex-start !important",
                    fontSize: "1rem",
                  }}
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls="panel1-content"
                >
                  <Typography
                    sx={{ flexGrow: "0 !important", fontSize: "1rem" }}
                  >
                    {menu.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                  {menu?.subRoutes?.map((subMenu: RouteItem) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        sx={{
                          padding: 0,
                          pl: 1,
                          ":hover": { color: "primary.main" },
                        }}
                        onClick={() => {
                          navigate(menu.path + "/" + subMenu.path);
                          handleDrawerClose();
                        }}
                      >
                        <ListItemText primary={subMenu?.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                {menu.key !== "router-error" &&
                  menu.key !== "router-policies" &&
                  menu.key !== "router-home" && (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        sx={{
                          padding: 0,
                          pl: 1,
                          ":hover": { color: "primary.main" },
                        }}
                        onClick={() => {
                          navigate(menu.path);
                          handleDrawerClose();
                        }}
                      >
                        <ListItemText primary={menu?.title} />
                      </ListItemButton>
                    </ListItem>
                  )}
              </>
            )}
          </>
        ))}
      </List>
      <Divider />
      <List>
        {otherMenus.map((erpLinks, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {erpLinks?.name === "Login" ? (
                  <img
                    src={`${constant?.baseurl}assets/images/webdesk_logo.png`}
                    alt="Webdesk Logo"
                    height="15px"
                  />
                ) : (
                  <ArrowRightAltIcon />
                )}
              </ListItemIcon>
              <Link href={erpLinks?.path} target="_blank">
                <ListItemText primary={erpLinks?.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const TrialSideBar = ({
  mobileOpen,
  setIsClosing,
  isClosing,
  setMobileOpen,
}: Props) => {
  const navigate = useNavigate();
  const [parentPath, setParentPath] = useState<string>("/");
  const [currentPath, setCurrentPath] = useState<string | null>("");
  const pathArray: Array<string> = window.location.pathname.split("/");
  const pathLength: number = pathArray.length;

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  useEffect(() => {
    if (pathLength === 3) {
      setCurrentPath(pathArray[2]);
      setParentPath(parentPath.concat(pathArray[1]));
    } else {
      setParentPath(parentPath.concat(pathArray[1]));
      setCurrentPath(null);
    }
  }, []);
  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onTransitionEnd={handleDrawerTransitionEnd}
      onClose={handleDrawerClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: 250,
        },
      }}
    >
      <Menus
        currentPath={currentPath}
        handleDrawerClose={handleDrawerClose}
        setCurrentPath={setCurrentPath}
        parentPath={parentPath}
        setParentPath={setParentPath}
      />
    </Drawer>
  );
};

export default TrialSideBar;
