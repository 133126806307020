import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import Title from "../components/Title";
import { useState } from "react";
import auditorium from "../static_data/facility/auditorium.json";
import labs from "../static_data/facility/labs.json";
import classrooms from "../static_data/facility/classrooms.json";
import playgrounds from "../static_data/facility/playgrounds.json";
import ImgGallery from "../components/ImgGallery";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ backgroundColor: "secondary.main", p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Props = {};

const Facilities = (props: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack direction="column">
      <Box p={2}>
        <Title title="Facilities" />
      </Box>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Auditorium" />
        <Tab label="Classrooms" />
        <Tab label="Labs" />
        <Tab label="PlayGround" />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <ImgGallery
          title="Auditorium"
          paddingLeft={0}
          imagesArray={auditorium}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ImgGallery
          title="Classrooms"
          paddingLeft={0}
          imagesArray={classrooms}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ImgGallery title="Labs" paddingLeft={0} imagesArray={labs} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ImgGallery
          title="PlayGround"
          paddingLeft={0}
          imagesArray={playgrounds}
          color="secondary.main"
        />
      </CustomTabPanel>
    </Stack>
  );
};

export default Facilities;
