import { Stack, Typography } from "@mui/material";
import React from "react";

type Props = {};

const HyperLinking = (props: Props) => {
  return (
    <Stack direction="column" alignItems={"center"} p={2} spacing={2}>
      <Typography variant="h5" textAlign={"justify"}>
        Hyperlinking Policy
      </Typography>

      <Typography variant="body2" textAlign="justify">
        You may encounter links to various government and non-government
        websites/portals on this website for easy access and convenience.
        ST.XAVIER'S SCHOOL KOLHAPUR is not responsible for the contents and
        reliability of the listed websites/portals and does not necessarily
        endorse the views expressed in them. We cannot guarantee that these
        links will work all the time, and we do not have any control over the
        availability of the contents and information on the associated pages of
        these website/portal linkages.
      </Typography>
    </Stack>
  );
};

export default HyperLinking;
