type Props = {};

const PageNotFound = (props: Props) => {
  return (
    <div
      style={{
        minHeight: "70vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundImage: `url("https://s3-cdn.cmlabs.co/page/2023/01/24/a-guideline-on-how-to-fix-error-404-not-found-effectively-519451.png")`,
      }}
    ></div>
  );
};

export default PageNotFound;
