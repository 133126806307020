import BodyLayout from "../../layouts/BodyLayout";
import Title from "../../components/Title";
import { Stack } from "@mui/material";
import Content from "../../components/Content";

type Props = {};

const Management = (props: Props) => {
  return (
    <BodyLayout>
      <Stack direction={"column"} width="95vw">
        <Title title="Management" />
        <Content content="St. Xavier’s High School is an English Medium School founded in 1957, at the express and pressing request of the people of Kolhapur. The school is recognized by the Government of Maharashtra and prepares pupils for the Secondary School Certificate Examination. It is owned by the Poona Catholic Educational Association (PCEA) and managed by the Poona Jesuit School’s Society (PJSS). The Society of Jesus, to which the Jesuit Fathers and Brothers belong is a Christian Religious Order founded by St. Ignatius of Loyola in 1540. Active in the filed of education throughout the world. In India nearly 500 schools, 38 Higher Secondary and Colleges, 14 Technical Institutes, 10 community colleges and 5 Business Administration Institutes in which thousands of young people, belonging to every social class, community and Linguistic group are being educated through the medium of both English and the regional languages. In the world over five lacs teaching and non-teaching lay collaborators are engaged in this apostolate. About four thousand Jesuits are engaged in this endeavor." />
        <Content content="Jesuit Education is inspired by a vision of man drawn from the life and teachings of Jesus Christ and based on the principles of the character formation elaborated by St. Ignatius of Loyola." />
        <Content content="The Jesuits engaged in education work throughout the world for the last 400 years, re-affirm their conviction that the formation of youth through their educational institutions continues to be one of the foremost services they can render to their people. They believe that an essential need of our country is for a community of persons with deep faith, sound knowledge, intellectual and emotional maturity and moral integrity, persons able and willing to render generous service to their fellow citizens in building up a truly human society. It is therefore to the formation of such community of persons that they commit their educational institution." />
        <Content content="The education they impart includes both physical, intellectual and emotional development as well as acquisition of useful skills in all of which they aim at excellence." />
        <Content content="Being at the service of the whole country, St. Xavier’s accepts student from every caste, creed and community and strives to educate them in the high ideals of humanism and unselfish service of their fellow men and women. Hence, the purpose of this school is not only to impart instruction but also to educate the whole person." />
      </Stack>
    </BodyLayout>
  );
};

export default Management;
