import BodyLayout from "../../layouts/BodyLayout";
import Title from "../../components/Title";
import { Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Content from "../../components/Content";
type Props = {};

const History = (props: Props) => {
  const contents = [
    "Value based English Education was the need of the hour. In 1950 the people of Kolhapur had to go all the way to Belgaum at St. Paul's where their wards stayed as boarders. In 1955 the boarding had 30 boys from Kolhapur.",
    "The members of the Rotary Club of Kolhapur played a leading role in approaching, convincing and persuading the Jesuits to start an English Medium School in the City. On 17th September 1956, 145 prominent citizens signed a petition and sent it to the Provincial.",
    "After the inception of St. Xavier’s, Fr. Miranda played a vital role in building a harmonious and dedicated staff. The struggles & challenges faced in the initial stags are always difficult. But with his Courage he maneuvered the administrative machinery and kept it functioning smoothly.",
    "These were the missionaries who never looked back once they started their work with zeal. The leveling of the games field, the construction and supervisory work was handled by them. The brothers worked selflessly to see their effort much fruit.",
    "On 13th June 1957, the feast of St. Anthony Bro Eric with his team of teachers began in full earnest to get the necessary arrangement for the formal inauguration of the school on 15th June.",
    "On 15th June 1957, Shanti Vidyalaya was inaugurated at the hands of Mr. G. R. Reddy, the then Collector of Kolhapur. 29th October 1958 was red letter day in the history of the School when 10 Acres & 30 Gunthas exchanged hands for a sum of one lakh rupees.",
    "The local people were not satisfied with a vernacular name. What better name could one think of than St. Xavier’s-The Patron Saint of Kolhapur Parish and a Shining gem in the crown of the Jesuits. This change of name occurred on 14th June 1959.",
    "The work of the school building started on 5th March 1959. The foundation stone was laid by none other than his royal highness the Maharaja of Kolhapur.",
    "On 13th March, 1960 her excellency Princess Indumati Ranisaheb inaugurated one wing of the school building. The Eastern wing was added between 1961 – 63 and the connecting wing in 1966.",
    "Rev. Fr. Bertie’s term as Principal of St. Xavier’s in 1981 … 1987 … 1990 added a spacious hall.",
    "In 1996 Fr. Joe Palliparambil put up the primary school building.",
    "Fr. Thomas Salve converted the ‘C’ shaped building was further made to ‘U’ shape with the extra wing being added.",
    "The first batch of S.S.C students took their Board exams in 1962. The students won laurels for their ‘Alma Mater’ by securing 100% result.",
  ];
  return (
    <BodyLayout>
      <Title title="History of the School" />
      {contents.map((content, i) => (
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"flex-start"}
          mb={1}
          width="97vw"
        >
          <ArrowForwardIosIcon fontSize="small" />
          <Content content={content} />
        </Stack>
      ))}
    </BodyLayout>
  );
};

export default History;
