import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState, ReactNode } from "react";

// 2023-24 imports
import annualday from "../../static_data/events2023_24/annualday.json";
import christmas from "../../static_data/events2023_24/christmas.json";
import farewell from "../../static_data/events2023_24/farewell.json";
import funfair from "../../static_data/events2023_24/funfair.json";
import msss from "../../static_data/events2023_24/msss.json";
import natioal_science_day from "../../static_data/events2023_24/national_science_day.json";
import republicday from "../../static_data/events2023_24/republicday.json";
import reopeningday23 from "../../static_data/events2023_24/reopening.json";

//2024-25 imports
import loyalafest24 from "../../static_data/events2024_25/loyalafest.json";
import reopeningday24 from "../../static_data/events2024_25/reopening.json";
import yoga24 from "../../static_data/events2024_25/yogaDay.json";
import ic24 from "../../static_data/events2024_25/ic.json";

import ImgGallery from "../ImgGallery";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  color: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, color, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ backgroundColor: color, p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Props = {};

const Events = (props: Props) => {
  const [value, setValue] = useState(0);

  const [value_23_24, setValue2324] = useState(0);

  const [value_24_25, setValue2425] = useState(0);
  const events24_25 = [
    {
      imgArray: loyalafest24,
      title: "St. Ignatius Loyola Fest",
      date: "02-08-2024",
    },
    {
      imgArray: reopeningday24,
      title: "Reopening Day",
      date: "03-06-2024",
    },
    {
      imgArray: yoga24,
      title: "Yoga Day",
      date: "22-06-2024",
    },
    {
      imgArray: ic24,
      title: "Investiture Ceremony",
      date: "01-07-2024",
    },
  ];
  const events23_24 = [
    {
      imgArray: annualday,
      title: "Annual Day",
      date: "23-07-2024",
    },
    {
      imgArray: christmas,
      title: "Christmas Day",
      date: "27-12-2023",
    },
    {
      imgArray: farewell,
      title: "Farewell Day",
      date: "17-05-2024",
    },
    {
      imgArray: funfair,
      title: "Fun Fair",
      date: "7-12-2023",
    },
    {
      imgArray: reopeningday23,
      title: "Reopening Day",
      date: "7-6-2023",
    },
    {
      imgArray: msss,
      title: "Sundar Shala Award from Maharashtra Govt.",
      date: "3-7-2023",
    },
    {
      imgArray: natioal_science_day,
      title: "National Science Day",
      date: "28-2-2024",
    },
    {
      imgArray: republicday,
      title: "Republic Day",
      date: "26-1-2024",
    },
  ];

  const parseDate = (dateStr: string): Date => {
    const [day, month, year] = dateStr.split("-").map(Number);
    return new Date(year, month, day);
  };
  const sortEventByDate = (
    events: Array<{ imgArray: any; title: string; date: string }>
  ) => {
    return events.sort(
      (a, b) => parseDate(a.date).getTime() - parseDate(b.date).getTime()
    );
  };
  const sortedEvent23_24 = sortEventByDate(events23_24).reverse();
  const sortedEvent24_25 = sortEventByDate(events24_25).reverse();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  const handleChange2324 = (event: any, newValue: number) => {
    setValue2324(newValue);
  };
  const handleChange2425 = (event: any, newValue: number) => {
    setValue2425(newValue);
  };
  return (
    <Stack direction="column">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="2024-25" />
        <Tab label="2023-24" />
      </Tabs>
      <CustomTabPanel value={value} index={0} color="secondary.main">
        <Tabs
          value={value_24_25}
          onChange={handleChange2425}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {sortedEvent24_25.map((heading, i) => (
            <Tab label={heading.title} key={i} />
          ))}
        </Tabs>
        {sortedEvent24_25.map((single_event, i) => (
          <CustomTabPanel value={value_24_25} index={i} color="white">
            <ImgGallery
              title=""
              paddingLeft={0}
              imagesArray={single_event.imgArray.images}
              color="white"
            />
          </CustomTabPanel>
        ))}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} color="secondary.main">
        <Tabs
          value={value_23_24}
          onChange={handleChange2324}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {sortedEvent23_24.map((heading, i) => (
            <Tab label={heading.title} key={i} />
          ))}
        </Tabs>
        {sortedEvent23_24.map((single_event, i) => (
          <CustomTabPanel value={value_23_24} index={i} color="white">
            <ImgGallery
              title=""
              paddingLeft={0}
              imagesArray={single_event.imgArray.images}
              color="white"
            />
          </CustomTabPanel>
        ))}
      </CustomTabPanel>
    </Stack>
  );
};

export default Events;
