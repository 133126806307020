import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CarouselDiv from "./test_layout/CarouselDiv";
import QuickLinks from "./test_layout/QuickLinks";
import ErpLinks from "../components/ErpLinks";
import { Stack, useScrollTrigger } from "@mui/material";
import TrialHeader from "./test_layout/TrialHeader";
import TrialSideBar from "./test_layout/TrialSideBar";
import TrialFooter from "./test_layout/TrialFooter";

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window;
  children: React.ReactNode;
}

export default function Layout2(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const [scrollTarget, setScrollTarget] = React.useState<
    Node | Window | undefined
  >();
  const scrollTrigger = useScrollTrigger({ target: scrollTarget });
  // Remove this const when copying and pasting into your project.

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" elevation={4} color={"primary"}>
        <Toolbar>
          <IconButton
            color="secondary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: { xs: 0, sm: 2 } }}
          >
            <MenuIcon />
          </IconButton>
          <TrialHeader />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <TrialSideBar
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          isClosing={isClosing}
          setIsClosing={setIsClosing}
        />
      </Box>
      <Box component="main">
        <CarouselDiv />
        <Box>{props.children}</Box>
        <TrialFooter />
        <QuickLinks />
      </Box>
    </Box>
  );
}
