import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

import home from "../../static_data/carousels/home.json";
import hr from "../../static_data/carousels/hr.json";
import contact from "../../static_data/carousels/contact.json";
import newsEvents from "../../static_data/carousels/newsEvents.json";
import facility from "../../static_data/carousels/facility.json";
import constant from "../../config/constants";
import CarouselComponent from "../../components/CarouselComponent";

const CarouselDiv = () => {
  const { pathname } = useLocation();
  const pathArray: Array<string> = pathname.split("/");
  let currentPath = "";
  let parentPath = "";
  if (pathArray.length === 3) {
    parentPath = pathArray[1];
    currentPath = pathArray[2];
  } else if (pathArray.length === 2) {
    currentPath = "";
    if (pathArray[1].length === 0) {
      parentPath = "home";
    } else {
      parentPath = pathArray[1];
    }
  }
  return (
    <Box
      sx={{
        // backgroundImage: `url(${constant.baseurl}/assets/images/bg-slider.jpg)`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "100% 100%",
        // minHeight: "50vh",
        borderBottom: "5px solid #158cde",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        // marginBottom: "9px",
      }}
    >
      {parentPath === "home" ||
      parentPath === "xaviers" ||
      parentPath === "academics" ||
      parentPath === "policies" ||
      parentPath === "school-history" ? (
        <CarouselComponent
          main={home.home}
          currentPath={currentPath}
          parentPath={parentPath}
        ></CarouselComponent>
      ) : parentPath === "human-resources" ? (
        <CarouselComponent
          main={hr.hr}
          currentPath={currentPath}
          parentPath={parentPath}
        ></CarouselComponent>
      ) : parentPath === "contact" ? (
        <CarouselComponent
          main={contact.contact}
          currentPath={currentPath}
          parentPath={parentPath}
        ></CarouselComponent>
      ) : parentPath === "news-events" ? (
        <CarouselComponent
          main={newsEvents.newsEvents}
          currentPath={currentPath}
          parentPath={parentPath}
        ></CarouselComponent>
      ) : parentPath === "facility" ? (
        <CarouselComponent
          main={facility.facility}
          currentPath={currentPath}
          parentPath={parentPath}
        ></CarouselComponent>
      ) : (
        ""
      )}
    </Box>
  );
};

export default CarouselDiv;
