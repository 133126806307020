import { Stack, Typography } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type Props = {};

const Copyright = (props: Props) => {
  return (
    <Stack direction="column" alignItems={"center"} p={2} spacing={2}>
      <Typography variant="h5" textAlign={"justify"}>
        Copyright Policy
      </Typography>

      <Typography variant="body2" textAlign="justify">
        This website and its content are the copyright of ST.XAVIER'S SCHOOL
        KOLHAPUR.
      </Typography>

      <Typography variant="body2" textAlign="justify">
        © ST.XAVIER'S SCHOOL KOLHAPUR. All rights reserved.
      </Typography>

      <Stack direction="column" spacing={2}>
        <Typography variant="body1" textAlign="justify">
          Any redistribution or reproduction of part or all of the contents in
          any form is prohibited other than the following:
        </Typography>
        <Typography variant="body2" textAlign="justify">
          <ArrowForwardIosIcon
            sx={{
              color: "text.secondary",
              fontSize: "medium",
              minWidth: "fit-content",
            }}
          />
          You may print or download to a local hard disk extracts for your
          personal and non-commercial use only.
        </Typography>
        <Typography variant="body2" textAlign="justify">
          <ArrowForwardIosIcon
            sx={{
              color: "text.secondary",
              fontSize: "medium",
              minWidth: "fit-content",
            }}
          />
          You may copy the content to individual third parties for their
          personal use, but only if you acknowledge the website as the source of
          the material.
        </Typography>
        <Typography variant="body2" textAlign="justify">
          <ArrowForwardIosIcon
            sx={{
              color: "text.secondary",
              fontSize: "medium",
              minWidth: "fit-content",
            }}
          />
          You may not, except with our express written permission, distribute or
          commercially exploit the content. Nor may you transmit it or store it
          in any other website or other form of electronic retrieval system.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Copyright;
